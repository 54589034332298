import React from 'react'
import { Redirect, Switch } from 'react-router-dom'
import axios from 'axios'
import apiUrl from './../../apiConfig'
import Profile from './Profile/Profile'
import Listings from './Listings/Listings'
import OptInModal from './OptInModal'
import { Segment, Dimmer, Loader } from 'semantic-ui-react'

class MainView extends React.Component {
  state = {
    loaded: false,
    theme: '',
    portalData: '',
    defaultListingData: '',
    listingData: '',
    featuredOpenHouseData: '',
    noOpenHouses: false,
    error404: false,
    optInModal: false,
    optedIn: false,
    contactFunction: '',
    targetAddress: '',
    leadFirstName: '',
    leadLastName: ''
  }

  componentDidMount () {
    // 628d1c282332c23a0cd3ec50
    const split = window.location.pathname.split('/')
    const portalID = split[1]
    // console.log(split)
    axios({
      url: apiUrl + '/portals/' + portalID,
      method: 'GET'
    })
      .then(res => this.setState({ portalData: res.data.portal, theme: res.data.portal.theme }))
      .then(() => {
        if (this.state.portalData.mlsinfo.mlsid === '123456789') {
          this.getListingData()
        } else {
          this.getOpenHouseData()
        }
      })
      .then(() => this.postClick(portalID))
      .catch(() => this.setState({ error404: true }))
  }

  // ci

  postClick = (portalID) => {
    axios({
      url: apiUrl + '/clicks',
      method: 'POST',
      data: {
        portal: portalID
      }
    })
  }

  getOpenHouseData = () => {
    axios({
      url: apiUrl + '/openhousegenie',
      method: 'POST',
      data: {
        listingid: this.state.portalData.featuredoh.id,
        mlsId: this.state.portalData.mlsinfo.mls
      }
    })
      .then(res => this.setOpenHouseData(res.data.listing.value))
  }

  setOpenHouseData = (dataArray) => {
    const newObject = {
      ListingId: dataArray[0].ListingId,
      Dates: []
    }
    dataArray.forEach((item) => {
      if (item.OpenHouseStatus !== 'Ended') {
        newObject.Dates.push({
          OpenHouseEndTime: item.OpenHouseEndTime,
          OpenHouseStartTime: item.OpenHouseStartTime,
          OpenHouseDate: item.OpenHouseDate
        })
      }
    })
    if (newObject.Dates.length >= 1) {
      this.setState({ featuredOpenHouseData: newObject },
        () => {
          this.getListingData()
        })
    } else {
      this.setBackupOpenHouse()
    }
  }

  setBackupOpenHouse = () => {
    axios({
      url: apiUrl + '/backupopenhouse',
      method: 'POST',
      headers: {
      },
      data: {
        mlsId: this.state.portalData.mlsinfo.mls,
        zip1: this.state.portalData.zipcodes.zip1,
        zip2: this.state.portalData.zipcodes.zip2,
        zip3: this.state.portalData.zipcodes.zip3,
        dateRangeStart: this.todayDate(),
        dateRangeEnd: this.getDate()
      }
    })
      .then(res => {
        if (res.data.openHouse.nextLinks) {
          if (res.data.openHouse.nextLinks.openHouse) {
            this.searchMoreBackupOpenHouses(res.data.openHouse.nextLinks.openHouse)
          } else {
            this.setState({ noOpenHouses: true })
          }
        } else {
          this.getBackupOpenHouseData(res.data.openHouse.ListingId)
        }
      })
  }

  searchMoreBackupOpenHouses = (link) => {
    axios({
      url: apiUrl + '/backupopenhouse',
      method: 'POST',
      headers: {
      },
      data: {
        mlsId: this.state.portalData.mlsinfo.mls,
        zip1: this.state.portalData.zipcodes.zip1,
        zip2: this.state.portalData.zipcodes.zip2,
        zip3: this.state.portalData.zipcodes.zip3,
        link: link
      }
    })
      .then(res => {
        if (res.data.openHouse.nextLinks) {
          if (res.data.openHouse.nextLinks.openHouse) {
            this.searchMoreBackupOpenHouses(res.data.openHouse.nextLinks.openHouse)
          } else {
            this.setState({ noOpenHouses: true })
          }
        } else if (res.data.openHouse.ListingId) {
          this.getBackupOpenHouseData(res.data.openHouse.ListingId)
        } else if (!res.data.openHouse.ListingId && !res.data.openHouse.nextLinks.openHouse) {
          this.setState({ noOpenHouses: true })
        }
      })
  }

  getBackupOpenHouseData = (id) => {
    axios({
      url: apiUrl + '/openhousegenie',
      method: 'POST',
      data: {
        listingid: id,
        mlsId: this.state.portalData.mlsinfo.mls
      }
    })
      .then(res => this.setOpenHouseData(res.data.listing.value))
  }

  getListingData = () => {
    if (this.state.portalData.mlsinfo.mlsid === '123456789') {
      axios({
        url: apiUrl + '/testlisting',
        method: 'POST'
      })
        .then(res => this.setState({ defaultListingData: res.data.listing.value[0] }))
        .then(() => { setTimeout(this.finishedLoading, 1000) })
    } else {
      axios({
        url: apiUrl + '/listinggenie',
        method: 'POST',
        data: {
          listingid: this.state.featuredOpenHouseData.ListingId,
          mlsId: this.state.portalData.mlsinfo.mls
        }
      })
        .then(res => this.setState({ listingData: res.data.listing.value[0] }))
        .then(() => { setTimeout(this.finishedLoading, 1000) })
    }
  }

  todayDate = () => {
    let theDay
    let month
    const currentDate = new Date()
    const date = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`
    const dateArr = date.split('-')
    if (dateArr[1] < 10) {
      theDay = '0'.concat(dateArr[1])
    } else {
      theDay = dateArr[1]
    }
    if (dateArr[2] < 10) {
      month = '0'.concat(dateArr[2])
    } else {
      month = dateArr[2]
    }
    return `${dateArr[0]}-${theDay}-${month}`
  }

  getDate = () => {
    const oldDate = new Date()
    oldDate.setDate(oldDate.getDate() + 7)
    return oldDate
  }

  finishedLoading = () => {
    this.setState({ loaded: true })
  }

  optInModalOpen = (funct, address) => {
    this.setState({ optInModal: true, contactFunction: funct, targetAddress: address })
  }

  optedInTrue = (event, first, last, phone, email) => {
    event.preventDefault()
    let leadChannel
    if (this.state.contactFunction === 'call') {
      leadChannel = 'Phone Call'
    } else if (this.state.contactFunction === 'textDefaultData' || this.state.contactFunction === 'textRealData' || this.state.contactFunction === 'textDefaultListings' || this.state.contactFunction === 'textRealListings') {
      leadChannel = 'Text Message'
    } else if (this.state.contactFunction === 'emailDefaultData' || this.state.contactFunction === 'emailRealData' || this.state.contactFunction === 'emailDefaultListings' || this.state.contactFunction === 'emailRealListings') {
      leadChannel = 'Email'
    }
    axios({
      url: apiUrl + '/leads',
      method: 'POST',
      data: {
        lead: {
          leadFirstName: first,
          leadLastName: last,
          leadEmail: email,
          leadPhone: phone,
          channel: leadChannel,
          portal: this.state.portalData._id
        }
      }
    })
    this.setState({ optedIn: true, optInModal: false, leadFirstName: first, leadLastName: last }, () => {
      this[this.state.contactFunction]()
    })
  }

  call = () => {
    const firstSplit = this.state.portalData.mobile.number.split('(')
    const secondSplit = firstSplit[1].split(')')
    const thirdSplit = secondSplit[1].split('-')
    const final = [secondSplit[0], thirdSplit[0], thirdSplit[1]]
    const noFormat = final.join('')
    const forHref = `tel:+1${noFormat}`
    window.open(`${forHref}`, '_self')
  }

  textDefaultData = () => {
    let forHref
    const firstSplit = this.state.portalData.mobile.number.split('(')
    const secondSplit = firstSplit[1].split(')')
    const thirdSplit = secondSplit[1].split('-')
    const final = [secondSplit[0], thirdSplit[0], thirdSplit[1]]
    const noFormat = final.join('')
    const body = `Hi ${this.state.portalData.name.first}, please contact me with more information about ${this.state.defaultListingData.StreetNumber} ${this.state.defaultListingData.StreetName} ${this.state.defaultListingData.StreetSuffix} ${this.state.defaultListingData.UnitNumber ? this.state.defaultListingData.UnitNumber : ''}. Thank You! -${this.state.leadFirstName} ${this.state.leadLastName}`
    if (/Android/i.test(navigator.userAgent)) {
      forHref = 'sms://+1' + noFormat + '?body=' + body
    } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      forHref = 'sms:+1' + noFormat + '&body=' + body
    }
    // // console.log(forHref)
    window.open(`${forHref}`, '_self')
  }

  textRealData = () => {
    let forHref
    const firstSplit = this.state.portalData.mobile.number.split('(')
    const secondSplit = firstSplit[1].split(')')
    const thirdSplit = secondSplit[1].split('-')
    const final = [secondSplit[0], thirdSplit[0], thirdSplit[1]]
    const noFormat = final.join('')
    const body = `Hi ${this.state.portalData.name.first}, please contact me with more information about ${this.state.listingData.StreetNumber} ${this.state.listingData.StreetName} ${this.state.listingData.StreetSuffix} ${this.state.listingData.UnitNumber ? this.state.listingData.UnitNumber : ''}. Thank You! -${this.state.leadFirstName} ${this.state.leadLastName}`
    if (/Android/i.test(navigator.userAgent)) {
      forHref = 'sms://+1' + noFormat + '?body=' + body
    } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      forHref = 'sms:+1' + noFormat + '&body=' + body
    }
    // // console.log(forHref)
    window.open(`${forHref}`, '_self')
  }

  textDefaultListings = () => {
    let forHref
    const firstSplit = this.state.portalData.mobile.number.split('(')
    const secondSplit = firstSplit[1].split(')')
    const thirdSplit = secondSplit[1].split('-')
    const final = [secondSplit[0], thirdSplit[0], thirdSplit[1]]
    const noFormat = final.join('')
    const body = `Hi ${this.state.portalData.name.first}, please contact me with more information about ${this.state.targetAddress}. Thank You! -${this.state.leadFirstName} ${this.state.leadLastName}`
    if (/Android/i.test(navigator.userAgent)) {
      forHref = 'sms://+1' + noFormat + '?body=' + body
    } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      forHref = 'sms:+1' + noFormat + '&body=' + body
    }
    // console.log(forHref)
    window.open(`${forHref}`, '_self')
  }

  textRealListings = () => {
    let forHref
    const firstSplit = this.state.portalData.mobile.number.split('(')
    const secondSplit = firstSplit[1].split(')')
    const thirdSplit = secondSplit[1].split('-')
    const final = [secondSplit[0], thirdSplit[0], thirdSplit[1]]
    const noFormat = final.join('')
    const body = `Hi ${this.state.portalData.name.first}, please contact me with more information about ${this.state.targetAddress}. Thank You! -${this.state.leadFirstName} ${this.state.leadLastName}`
    if (/Android/i.test(navigator.userAgent)) {
      forHref = 'sms://+1' + noFormat + '?body=' + body
    } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      forHref = 'sms:+1' + noFormat + '&body=' + body
    }
    // console.log(forHref)
    window.open(`${forHref}`, '_self')
  }

  emailRealData = () => {
    const body = `Hi ${this.state.portalData.name.first}, please contact me with more information about ${this.state.listingData.StreetNumber} ${this.state.listingData.StreetName} ${this.state.listingData.StreetSuffix} ${this.state.listingData.UnitNumber ? this.state.listingData.UnitNumber : ''}. Thank You! -${this.state.leadFirstName} ${this.state.leadLastName}`
    const forHref = 'mailto:' + 'akeem@openhousegenie.com' + '?subject=Openhouse Inquiry - OHG&body=' + body
    // console.log(forHref)
    window.open(`${forHref}`, '_self')
  }

  emailDefaultData = () => {
    const body = `Hi ${this.state.portalData.name.first}, please contact me with more information about ${this.state.defaultListingData.StreetNumber} ${this.state.defaultListingData.StreetName} ${this.state.defaultListingData.StreetSuffix} ${this.state.defaultListingData.UnitNumber ? this.state.defaultListingData.UnitNumber : ''}. Thank You! -${this.state.leadFirstName} ${this.state.leadLastName}`
    const forHref = 'mailto:' + 'akeem@openhousegenie.com' + '?subject=Openhouse Inquiry - OHG&body=' + body
    // console.log(forHref)
    window.open(`${forHref}`, '_self')
  }

  emailDefaultListings = () => {
    const body = `Hi ${this.state.portalData.name.first}, please contact me with more information about ${this.state.targetAddress}. Thank You! -${this.state.leadFirstName} ${this.state.leadLastName}`
    const forHref = 'mailto:' + 'akeem@openhousegenie.com' + '?subject=Openhouse Inquiry - OHG&body=' + body
    // console.log(forHref)
    window.open(`${forHref}`, '_self')
  }

  emailRealListings = () => {
    const body = `Hi ${this.state.portalData.name.first}, please contact me with more information about ${this.state.targetAddress}. Thank You! -${this.state.leadFirstName} ${this.state.leadLastName}`
    const forHref = 'mailto:' + 'akeem@openhousegenie.com' + '?subject=Openhouse Inquiry - OHG&body=' + body
    // console.log(forHref)
    window.open(`${forHref}`, '_self')
  }

  render () {
    if (this.state.error404) {
      return <Switch><Redirect to = {{ pathname: '/notfound' }}></Redirect></Switch>
    }
    return (
      <React.Fragment>
        {!this.state.noOpenHouses &&
        <div style={{ margin: '0 auto' }} className={'main-view' + ' ' + this.state.theme }>
          {this.state.loaded &&
          <React.Fragment>
            <Profile {...this.state} optInModalOpen={this.optInModalOpen}></Profile>
            <Listings {...this.state} optInModalOpen={this.optInModalOpen}></Listings>
          </React.Fragment>
          }
          {!this.state.loaded &&
          <div className={this.state.theme} style={{ height: '100vh' }}>
            <Segment className={this.state.theme} style={{ height: '100%', border: 'none', boxShadow: 'none' }}>
              <Dimmer className={this.state.theme} style={{ border: 'none' }} active>
                <Loader style={{ border: 'none', color: 'white' }} size='massive'>Loading</Loader>
              </Dimmer>
            </Segment>
          </div>
          }
        </div>
        }
        {this.state.noOpenHouses &&
          <div style={{ margin: '0 auto', height: '100vh' }} className={'main-view' + ' ' + this.state.theme }>
            <div style={{ width: '100%', height: '100%' }} className="d-flex flex-column justify-content-center">
              <p style={{ textAlign: 'center', color: 'white' }}>Currently there are no open houses in the area to display. Please check back later</p>
            </div>
          </div>
        }
        <OptInModal {...this.state} optInModalClose={() => this.setState({ optInModal: false })} optedInTrue={this.optedInTrue}></OptInModal>
      </React.Fragment>
    )
  }
}

export default MainView
