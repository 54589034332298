import React from 'react'

class Error404View extends React.Component {
  state= {

  }

  render () {
    return (
      <React.Fragment>
        <div style={{ margin: '0 auto', height: '100vh' }} className='main-view'>
          <div style={{ width: '100%', height: '100%' }} className="d-flex flex-column justify-content-center">
            <p style={{ textAlign: 'center' }}>Sorry, but the page you are looking for does not exist.</p>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Error404View
