import React from 'react'
// import axios from 'axios'
// import apiUrl from '../../apiConfig'
import InputMask from 'react-input-mask'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'

class OptInModal extends React.Component {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    phone: ''
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render () {
    return (
      <React.Fragment>
        <Modal show={this.props.optInModal} centered onHide={this.props.optInModalClose}>
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body className="main-font-style">
            <p>Opt in to stay up to date with Open Houses in your area!</p>
            <Form onSubmit={(event) => this.props.optedInTrue(event, this.state.firstName, this.state.lastName, this.state.phone, this.state.email)}>
              <Row className="mb-2">
                <Col>
                  <Form.Control
                    required
                    type="text"
                    placeholder="first name *"
                    name="firstName"
                    onChange={this.handleChange}
                  />
                </Col>
                <Col>
                  <Form.Control
                    required
                    type="text"
                    placeholder="last name *"
                    name="lastName"
                    onChange={this.handleChange}
                  />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <Form.Control
                    required
                    type="email"
                    placeholder="email *"
                    name="email"
                    onChange={this.handleChange}
                  />
                </Col>
                <Col>
                  <InputMask
                    mask="(999)999-9999"
                    maskChar=" "
                    className="form-control"
                    placeholder="phone"
                    name="phone"
                    onChange={this.handleChange}
                  />
                </Col>
              </Row>
              <Form.Group className="d-flex justify-content-end">
                <Form.Check required type="checkbox" label="Opt In" />
              </Form.Group>
              <Form.Group className="d-flex justify-content-center">
                <Button type="submit">Submit</Button>
              </Form.Group>
            </Form>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    )
  }
}

export default OptInModal
