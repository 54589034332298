import React from 'react'
// import QRCode from 'qrcode'
import { QrReader } from 'react-qr-reader'
import Button from 'react-bootstrap/Button'
import { Loader } from 'semantic-ui-react'
import Logo from './../../images/logo.png'

class LandingView extends React.Component {
  state= {
    url: '',
    error: '',
    openCamera: false
  }

  scanResult = (result, error) => {
    if (result) {
      this.setState({ url: result.text, error: '', openCamera: false })
      window.open(`${result.text}`, '_self')
    } else {
      this.setState({ error: 'Reading...', url: '' })
    }
  }

  // findAgent = () => {
  //   if (/Android/i.test(navigator.userAgent)) {
  //     return 'Android'
  //   } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
  //     return 'iPhone'
  //   } else {
  //     return 'nothing'
  //   }
  // }

  render () {
    const error = this.state.error && this.state.openCamera
    const result = this.state.url && this.state.openCamera
    return (
      <React.Fragment>
        <div style={{ margin: '0 auto', height: '100vh' }} className='main-view'>
          <div style={{ width: '100%', height: '100%' }} className="d-flex flex-column justify-content-center">
            <div style={{ width: '100%' }} className='d-flex justify-content-center'>
              <div style={{ width: '55%' }} className='ratio ratio-16x9'>
                <img className='' src={Logo} alt='genie-logo' />
              </div>
            </div>
            <div style={{ width: '100%' }} className="d-flex flex-column justify-content-center">
              <div>
                {!this.state.openCamera &&
                <p className="main-font-stlye" style={{ textAlign: 'center', fontSize: '1.2rem', paddingLeft: '.5rem', paddingRight: '.5rem' }}>Welcome to OpenHouse Genie. Open your camera to scan the QR Code by clicking the button below.</p>
                }
                {error &&
                  <div className="main-font-stlye" style={{ textAlign: 'center', fontSize: '1.2rem', paddingLeft: '.5rem', paddingRight: '.5rem' }}>{this.state.error}  <Loader size='mini' active inline /></div>
                }
                {result &&
                  <div>
                    <p className="main-font-stlye" style={{ textAlign: 'center', fontSize: '1.2rem', paddingLeft: '.5rem', paddingRight: '.5rem' }}>{this.state.url}</p>
                  </div>
                }
              </div>
              <div style={{ width: '100%' }}>
                {this.state.openCamera &&
                <QrReader
                  scanDelay={1000}
                  onResult={(result, error) => this.scanResult(result, error)}
                  constraints ={{ facingMode: 'environment' }}
                />
                }
              </div>
              <div className="d-flex justify-content-center">
                {!this.state.openCamera &&
                <Button className='mt-3 main-font-style' onClick={() => this.setState({ openCamera: true })} variant='geniebelt' style={{ color: 'white' }}>Scan Code</Button>
                }
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default LandingView
