import React from 'react'
import apiUrl from '../../../apiConfig'
import axios from 'axios'
import InfiniteScroll from 'react-infinite-scroller'
import Container from 'react-bootstrap/Container'
import Spinner from 'react-bootstrap/Spinner'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Carousel from 'react-bootstrap/Carousel'
import { Grid, Icon, Popup } from 'semantic-ui-react'

class DefaultListings extends React.Component {
  state = {
    componentLoading: true,
    testListings: '',
    testNextLink: '',
    testFullDataCount: '',
    testCurrentDataCount: '',
    testHasMoreData: '',
    testSkip: '',
    imageModal: false,
    media: ''
  }

  componentDidMount () {
    axios({
      url: apiUrl + '/testlistings',
      method: 'POST',
      headers: {
      }
    })
      .then(res => {
        this.setState({ testListings: res.data.listings.value, testFullDataCount: res.data.listings['@odata.count'] })
        if (res.data.listings['@odata.nextLink']) {
          this.setState({ testNextLink: res.data.listings['@odata.nextLink'], testHasMoreData: true, testSkip: 10 })
        }
      })
      .then(() => this.setState({ componentLoading: false }))
  }

  fetchTestItems = () => {
    if (this.state.testHasMoreData) {
      axios({
        url: apiUrl + '/testlistings',
        method: 'POST',
        headers: {
        },
        data: {
          skip: this.state.testSkip
        }
      })
        .then(res => {
          const oldValues = this.state.testListings
          const newValues = res.data.listings.value
          const newState = oldValues.concat(newValues)
          this.setState({ testListings: newState, testCurrentDataCount: res.data.listings['@odata.count'] })
          if (res.data.listings['@odata.nextLink']) {
            const skip = parseFloat(this.state.testSkip) + 10
            this.setState({ testNextLink: res.data.listings['@odata.nextLink'], testHasMoreData: true, testSkip: skip })
          }
          if (this.state.testSkip > 100) {
            this.setState({ testNextLink: '', testHasMoreData: false })
          }
        })
    }
  }

  imageModal = (listing) => {
    const mediaListing = this.state.testListings.find((index) => {
      return index['@odata.id'] === listing['@odata.id']
    })
    this.setState({ media: mediaListing.Media, imageModal: true })
  }

  call = (number, address) => {
    if (this.props.optedIn) {
      const firstSplit = number.split('(')
      const secondSplit = firstSplit[1].split(')')
      const thirdSplit = secondSplit[1].split('-')
      const final = [secondSplit[0], thirdSplit[0], thirdSplit[1]]
      const noFormat = final.join('')
      const forHref = `tel:+1${noFormat}`
      window.open(`${forHref}`, '_self')
    } else {
      this.props.optInModalOpen('call', address)
    }
  }

  textDefaultListings = (number, address) => {
    if (this.props.optedIn) {
      let forHref
      const firstSplit = number.split('(')
      const secondSplit = firstSplit[1].split(')')
      const thirdSplit = secondSplit[1].split('-')
      const final = [secondSplit[0], thirdSplit[0], thirdSplit[1]]
      const noFormat = final.join('')
      const body = `Hi ${this.props.portalData.name.first}, please contact me with more information about ${address}. Thank You  -${this.props.leadFirstName} ${this.props.leadLastName}`
      if (/Android/i.test(navigator.userAgent)) {
        forHref = 'sms://+1' + noFormat + '?body=' + body
      } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        forHref = 'sms:+1' + noFormat + '&body=' + body
      }
      // console.log(forHref)
      window.open(`${forHref}`, '_self')
    } else {
      this.props.optInModalOpen('textDefaultListings', address)
    }
  }

  emailDefaultListings = (email, address) => {
    if (this.props.optedIn) {
      const body = `Hi ${this.props.portalData.name.first}, please contact me with more information about ${address}. Thank You  -${this.props.leadFirstName} ${this.props.leadLastName}`
      const forHref = 'mailto:' + email + '?subject=Openhouse Inquiry - OHG&body=' + body
      // console.log(forHref)
      window.open(`${forHref}`, '_self')
    } else {
      this.props.optInModalOpen('emailDefaultListings', address)
    }
  }

  mapSelector = (number, name, suffix, city, state, zip) => {
    window.open(`http://maps.apple.com/?t=h&daddr=${number} ${name} ${suffix}, ${city}, ${state}, ${zip}`)
  }

  render () {
    return (
      <Container fluid id="listings">
        {!this.state.componentLoading &&
        <InfiniteScroll
          loadMore={this.fetchTestItems}
          hasMore={this.state.testHasMoreData}
          loader={
            <div className="d-flex justify-content-center mt-5">
              {this.state.testHasMoreData &&
            <div>
              <Spinner style={{ color: 'white' }} size="lg" animation="border"></Spinner>&nbsp;<span style={{ color: 'white' }}>Loading...</span>
            </div>
              }
              {!this.state.testHasMoreData &&
            <p style={{ textAlign: 'center' }}>No more data</p>
              }
            </div>
          }
        >
          <div>
            {this.state.testListings.map((listing) => (
              <div key={listing['@odata.id']}>
                <div className='ratio ratio-16x9 mt-3'>
                  <div onClick={() => this.imageModal(listing)} style={{ backgroundImage: `url(${listing.Media[0].MediaURL})` }} className="profile-img-sect">
                    <div style={{ marginTop: '30%' }}>
                      <div style={{ backgroundColor: 'rgba(46,46,61,0.75)', width: '100%', textAlign: 'center', marginBottom: '.2rem', color: 'white' }} className="secondary-font-style small-text-profile">July 06th 11:30AM-2:00PM</div>
                      <div style={{ backgroundColor: 'rgba(46,46,61,0.75)', width: '100%', textAlign: 'center', marginBottom: '.2rem', color: 'white' }} className="secondary-font-style small-text-profile">July 07th 11:30AM-2:00PM</div>
                    </div>
                  </div>
                </div>
                <div style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, .25)', paddingLeft: '.5rem', paddingTop: '.2rem', paddingBottom: '.2rem', backgroundColor: 'white' }}>
                  <div style={{ width: '100% ' }} className="d-flex flex-row">
                    <div style={{ width: '80%' }} className='main-font-style small-text mb-1'>
                      <div style={{ fontWeight: 'bold' }} className="secondary-font-style">${this.state.componentLoading ? '' : listing.ListPrice.toLocaleString()}</div>
                      <div>{listing.BedroomsTotal} Beds | {listing.BathroomsFull} Baths | {listing.LivingArea} SQFT</div>
                      <div>123 Main Street, Tampa, FL 33333</div>
                    </div>
                    <div style={{ width: '20%' }} className='secondary-font-style arrow-font'>
                      <p style={{ textAlign: 'right', paddingRight: '1rem', fontWeight: 'bold', paddinBottom: '0', marginBottom: '0' }}>Listed By:</p><p style={{ paddingTop: '0', marginTop: '0', paddingBottom: '0', marginBottom: '0', paddingRight: '1rem', textAlign: 'right' }}>{listing.ListOfficeName}</p>
                    </div>
                  </div>
                  <div style={{ width: '100%' }} className='d-flex justify-content-center'>
                    <div></div>
                    {!this.props.optInModal &&
                    <Popup hoverable inverted position='top center' trigger={<Button className='variable-buttons main-font-style show-button' style={{ color: 'white', border: 'none' }}>More Info</Button>}>
                      <Grid className={this.props.theme} divided columns='equal'>
                        <Grid.Column>
                          <Icon name='call' onClick={() => this.call(this.props.portalData.mobile.number, '123 Main Street')} />
                        </Grid.Column>
                        <Grid.Column>
                          <Icon name='talk' onClick={() => this.textDefaultListings(this.props.portalData.mobile.number, '123 Main Street')}/>
                        </Grid.Column>
                        <Grid.Column>
                          <Icon name='mail' onClick={() => this.emailDefaultListings(this.props.portalData.name.email, '123 Main Street')} />
                        </Grid.Column>
                      </Grid>
                    </Popup>
                    }
                  </div>
                  <div style={{ width: '100%' }} className='d-flex justify-content-end mb-2'>
                    <Icon style={{ paddingRight: '3rem' }} onClick={() => this.mapSelector('123', 'Main', 'Street', 'Tampa', 'FL', '33607')} name='location arrow' size='large' />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </InfiniteScroll>
        }
        {this.state.media &&
        <Modal size="lg" show={this.state.imageModal} backdrop={true} centered>
          <Modal.Body>
            <Carousel variant="dark" indicators={false}>
              {this.state.media.map((image) => (
                <Carousel.Item key={image.MediaURL}>
                  <Carousel.Caption style={{ width: '100%', left: '0', right: '0', bottom: '0', top: '0', paddingTop: '0', paddingBottom: '0' }}>
                    <div className="d-flex justify-content-end" style={{ width: '100%' }}>
                      <Button onClick={() => this.setState({ imageModal: false })} className="carousel-button" variant="outline-primary" style={{ border: 'none', color: 'black' }}>X</Button>
                    </div>
                  </Carousel.Caption>
                  <img
                    className="d-block w-100"
                    src={image.MediaURL}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </Modal.Body>
        </Modal>
        }
      </Container>
    )
  }
}

export default DefaultListings
