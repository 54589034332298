import React from 'react'
import apiUrl from '../../../apiConfig'
import axios from 'axios'
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroller'
import Container from 'react-bootstrap/Container'
import Spinner from 'react-bootstrap/Spinner'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Carousel from 'react-bootstrap/Carousel'
import { Grid, Icon, Popup } from 'semantic-ui-react'

class RealListings extends React.Component {
  state = {
    componentLoading: true,
    listings: '',
    nextLink: '',
    hasMoreData: false,
    skip: '',
    openHouses: '',
    renderedListings: [],
    imageModal: false
  }

  componentDidMount () {
    axios({
      url: apiUrl + '/openhouses',
      method: 'POST',
      headers: {
      },
      data: {
        dateRangeStart: this.todayDate(),
        dateRangeEnd: this.getDate(),
        mlsId: this.props.portalData.mlsinfo.mls
      }
    })
      .then(res => {
        this.setOpenHouseData(res.data.listings)
      })
      .then(this.getListings)
  }

  setOpenHouseData = (openHouseArray) => {
    const openHouseData = []
    openHouseArray.forEach((value) => {
      const check = openHouseData.some((item) => {
        return value.ListingId === item.ListingId
      })
      if (!check) {
        openHouseData.push({
          ListingId: value.ListingId,
          Dates: []
        })
      }
    })
    openHouseData.forEach((value) => {
      openHouseArray.forEach((item) => {
        if (value.ListingId === item.ListingId) {
          if (value.Dates.length <= 2) {
            value.Dates.push({
              OpenHouseDate: item.OpenHouseDate,
              OpenHouseStartTime: item.OpenHouseStartTime,
              OpenHouseEndTime: item.OpenHouseEndTime
            })
          }
        }
      })
    })
    this.setState({ openHouses: openHouseData })
  }

  getDate = () => {
    const oldDate = new Date()
    oldDate.setDate(oldDate.getDate() + 7)
    return oldDate
  }

  getListings = () => {
    axios({
      url: apiUrl + '/listings',
      method: 'POST',
      headers: {
      },
      data: {
        mlsId: this.props.portalData.mlsinfo.mls,
        zip1: this.props.portalData.zipcodes.zip1,
        zip2: this.props.portalData.zipcodes.zip2,
        zip3: this.props.portalData.zipcodes.zip3
      }
    })
      .then(res => {
        // console.log(res.data.listings)
        this.renderListings(res.data.listings.value)
        if (res.data.listings['@odata.nextLink']) {
          this.setState({ nextLink: res.data.listings['@odata.nextLink'], hasMoreData: true, skip: 10 })
        }
      })
  }

    // for each listing, loop through the open houseses, and return where the mlsid === listingid. Push that value into an array. Then render that array
    renderListings = (market) => {
      const arr = []
      market.forEach((market) => {
        this.state.openHouses.forEach((oh) => {
          if (market.ListingId === oh.ListingId) {
            arr.push({
              mlsId: market.ListingId,
              image: market.Media[0].MediaURL,
              media: market.Media,
              dates: oh.Dates,
              price: market.ListPrice,
              beds: market.BedroomsTotal,
              baths: market.BathroomsFull,
              sqft: market.LivingArea,
              address: market.UnparsedAddress,
              office: market.ListOfficeName
            })
          }
        })
      })
      const oldArr = this.state.renderedListings
      const newArr = oldArr.concat(arr)
      this.setState({ renderedListings: newArr, componentLoading: false })
    }

  fetchItems = () => {
    if (this.state.hasMoreData) {
      axios({
        url: apiUrl + '/listings',
        method: 'POST',
        headers: {
        },
        data: {
          skip: this.state.skip,
          mlsId: this.props.portalData.mlsinfo.mls,
          zip1: this.props.portalData.zipcodes.zip1,
          zip2: this.props.portalData.zipcodes.zip2,
          zip3: this.props.portalData.zipcodes.zip3
        }
      })
        .then(res => {
          // console.log(res.data)
          this.renderListings(res.data.listings.value)
          if (res.data.listings['@odata.nextLink']) {
            const skip = parseFloat(this.state.skip) + 10
            this.setState({ nextLink: res.data.listings['@odata.nextLink'], hasMoreData: true, skip: skip })
          } else {
            this.setState({ hasMoreData: false, nextLink: '', skip: '' })
          }
        })
    }
  }

  todayDate = () => {
    let theDay
    let month
    const currentDate = new Date()
    const date = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`
    const dateArr = date.split('-')
    if (dateArr[1] < 10) {
      theDay = '0'.concat(dateArr[1])
    } else {
      theDay = dateArr[1]
    }
    if (dateArr[2] < 10) {
      month = '0'.concat(dateArr[2])
    } else {
      month = dateArr[2]
    }
    return `${dateArr[0]}-${theDay}-${month}`
  }

  convertDate = (input) => {
    // console.log(input, 'date')
    const myDate = new Date(input)
    const dateString = myDate.toString()
    const dateArr = dateString.split(' ')
    const newArr = [dateArr[0], dateArr[1], dateArr[2]]
    return newArr.join(' ')
  }

  convertTime = (input) => {
    // console.log(input, 'time')
    const myDate = new Date(input)
    // console.log(myDate)
    const toLocal = myDate.toString()
    const dateArr = toLocal.split(' ')
    // console.log(dateArr)
    const string = dateArr[4]
    return moment(string, 'HH:mm:ss').format('h:mm a')
  }

  imageModal = (listing) => {
    // console.log(listing, 'listing')
    const mediaListing = this.state.renderedListings.find((index) => {
      return index.mlsId === listing.mlsId
    })
    this.setState({ media: mediaListing.media, imageModal: true })
  }

  call = (number, address) => {
    if (this.props.optedIn) {
      const firstSplit = number.split('(')
      const secondSplit = firstSplit[1].split(')')
      const thirdSplit = secondSplit[1].split('-')
      const final = [secondSplit[0], thirdSplit[0], thirdSplit[1]]
      const noFormat = final.join('')
      const forHref = `tel:+1${noFormat}`
      window.open(`${forHref}`, '_self')
    } else {
      this.props.optInModalOpen('call', address)
    }
  }

  textRealListings = (number, address) => {
    if (this.props.optedIn) {
      let forHref
      const firstSplit = number.split('(')
      const secondSplit = firstSplit[1].split(')')
      const thirdSplit = secondSplit[1].split('-')
      const final = [secondSplit[0], thirdSplit[0], thirdSplit[1]]
      const noFormat = final.join('')
      const body = `Hi ${this.props.portalData.name.first}, please contact me with more information about ${address}. Thank You  -${this.props.leadFirstName} ${this.props.leadLastName}`
      if (/Android/i.test(navigator.userAgent)) {
        forHref = 'sms://+1' + noFormat + '?body=' + body
      } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        forHref = 'sms:+1' + noFormat + '&body=' + body
      }
      // console.log(forHref)
      window.open(`${forHref}`, '_self')
    } else {
      this.props.optInModalOpen('textRealListings', address)
    }
  }

  emailRealListings = (email, address) => {
    if (this.props.optedIn) {
      const body = `Hi ${this.props.portalData.name.first}, please contact me with more information about ${address}. Thank You  -${this.props.leadFirstName} ${this.props.leadLastName}`
      const forHref = 'mailto:' + email + '?subject=Openhouse Inquiry - OHG&body=' + body
      // console.log(forHref)
      window.open(`${forHref}`, '_self')
    } else {
      this.props.optInModalOpen('emailRealListings', address)
    }
  }

  mapSelector = (address) => {
    window.open(`http://maps.apple.com/?t=h&daddr=${address}`)
  }

  render () {
    const showMessage = this.state.renderedListings.length < 10 && !this.state.componentLoading
    return (
      <Container className="mb-3" fluid id="listings">
        {!this.state.componentLoading &&
        <InfiniteScroll
          loadMore={this.fetchItems}
          hasMore={this.state.hasMoreData}
          loader={
            <div className="d-flex justify-content-center mt-5">
              {this.state.hasMoreData &&
            <div>
              <Spinner style={{ color: 'white' }} size="lg" animation="border"></Spinner>&nbsp;Loading...
            </div>
              }
              {!this.state.hasMoreData &&
            <p style={{ textAlign: 'center' }}>No more data</p>
              }
            </div>
          }
        >
          <div>
            {this.state.renderedListings.map((listing) => (
              <div key={listing.mlsId}>
                <div className='ratio ratio-16x9 mt-3'>
                  <div onClick={() => this.imageModal(listing)} style={{ backgroundImage: `url(${listing.image})` }} className="profile-img-sect">
                    <div style={{ marginTop: '30%' }}>
                      {listing.dates.map((date) => (
                        <div key={date.OpenHouseStartTime} style={{ backgroundColor: 'rgba(46,46,61,0.75)', width: '100%', textAlign: 'center', marginBottom: '.2rem', color: 'white' }} className="secondary-font-style small-text-profile">{this.state.componentLoading ? '' : this.convertDate(date.OpenHouseStartTime)} | {this.state.componentLoading ? '' : this.convertTime(date.OpenHouseStartTime)} - {this.state.componentLoading ? '' : this.convertTime(date.OpenHouseEndTime)}</div>
                      ))}
                    </div>
                  </div>
                </div>
                <div style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, .25)', paddingLeft: '.5rem', paddingTop: '.2rem', paddingBottom: '.2rem', backgroundColor: 'white' }}>
                  <div style={{ width: '100% ' }} className="d-flex flex-row">
                    <div style={{ width: '80%' }} className='main-font-style small-text mb-1'>
                      <div style={{ fontWeight: 'bold' }} className="secondary-font-style">${this.state.componentLoading ? '' : listing.price.toLocaleString()}</div>
                      <div>{listing.beds} Beds | {listing.baths} Baths | {listing.sqft} SQFT</div>
                      <div>{listing.address}</div>
                    </div>
                    <div style={{ width: '20%' }} className='secondary-font-style arrow-font'>
                      <p style={{ textAlign: 'right', paddingRight: '1rem', fontWeight: 'bold', paddinBottom: '0', marginBottom: '0' }}>Listed By:</p><p style={{ paddingTop: '0', marginTop: '0', paddingBottom: '0', marginBottom: '0', paddingRight: '1rem', textAlign: 'right' }}>{listing.office}</p>
                    </div>
                  </div>
                  <div style={{ width: '100%' }} className='d-flex justify-content-center'>
                    {!this.props.optInModal &&
                    <Popup hoverable inverted position='top center' trigger={<Button className='variable-buttons main-font-style show-button' style={{ color: 'white', border: 'none' }}>More Info</Button>}>
                      <Grid className={this.props.theme} divided columns='equal'>
                        <Grid.Column>
                          <Icon name='call' onClick={() => this.call(this.props.portalData.mobile.number, listing.address)} />
                        </Grid.Column>
                        <Grid.Column>
                          <Icon name='talk' onClick={() => this.textRealListings(this.props.portalData.mobile.number, listing.address)}/>
                        </Grid.Column>
                        <Grid.Column>
                          <Icon name='mail' onClick={() => this.emailRealListings(this.props.portalData.name.email, listing.address)} />
                        </Grid.Column>
                      </Grid>
                    </Popup>
                    }
                  </div>
                  <div style={{ width: '100%' }} className='d-flex justify-content-end mb-2'>
                    <Icon style={{ paddingRight: '3rem' }} onClick={() => this.mapSelector(listing.address)} name='location arrow' size='large' />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </InfiniteScroll>
        }
        {showMessage &&
          <div style={{ width: '100%', color: 'white' }}>
            <p className='main-font-style' style={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold' }}>That is all of the Open Houses for now. Please check again later.</p>
          </div>
        }
        {this.state.media &&
        <Modal size="lg" show={this.state.imageModal} backdrop={true} centered>
          <Modal.Body>
            <Carousel variant="dark" indicators={false}>
              {this.state.media.map((image) => (
                <Carousel.Item key={image.MediaURL}>
                  <Carousel.Caption style={{ width: '100%', left: '0', right: '0', bottom: '0', top: '0', paddingTop: '0', paddingBottom: '0' }}>
                    <div className="d-flex justify-content-end" style={{ width: '100%' }}>
                      <Button onClick={() => this.setState({ imageModal: false })} className="carousel-button" variant="outline-primary" style={{ border: 'none', color: 'black' }}>X</Button>
                    </div>
                  </Carousel.Caption>
                  <img
                    className="d-block w-100"
                    src={image.MediaURL}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </Modal.Body>
        </Modal>
        }
      </Container>
    )
  }
}

export default RealListings
