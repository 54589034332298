import React from 'react'
import { Segment, Grid, Button, Image, Icon, Popup } from 'semantic-ui-react'

class AgentDetails extends React.Component {
  state = {
  }

  scrollDown = () => {
    window.scrollTo({ top: 800, behavior: 'smooth' })
  }

  call = (number) => {
    if (this.props.optedIn) {
      const firstSplit = number.split('(')
      const secondSplit = firstSplit[1].split(')')
      const thirdSplit = secondSplit[1].split('-')
      const final = [secondSplit[0], thirdSplit[0], thirdSplit[1]]
      const noFormat = final.join('')
      const forHref = `tel:+1${noFormat}`
      window.open(`${forHref}`, '_self')
    } else {
      this.props.optInModalOpen('call')
    }
  }

  textDefaultData = (number) => {
    if (this.props.optedIn) {
      let forHref
      const firstSplit = number.split('(')
      const secondSplit = firstSplit[1].split(')')
      const thirdSplit = secondSplit[1].split('-')
      const final = [secondSplit[0], thirdSplit[0], thirdSplit[1]]
      const noFormat = final.join('')
      const body = `Hi ${this.props.portalData.name.first}, please contact me with more information about ${this.props.defaultListingData.StreetNumber} ${this.props.defaultListingData.StreetName} ${this.props.defaultListingData.StreetSuffix} ${this.props.defaultListingData.UnitNumber ? this.props.defaultListingData.UnitNumber : ''}. Thank You  -${this.props.leadFirstName} ${this.props.leadLastName}`
      if (/Android/i.test(navigator.userAgent)) {
        forHref = 'sms://+1' + noFormat + '?body=' + body
      } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        forHref = 'sms:+1' + noFormat + '&body=' + body
      }
      // console.log(forHref)
      window.open(`${forHref}`, '_self')
    } else {
      this.props.optInModalOpen('textDefaultData')
    }
  }

  textRealData = (number) => {
    if (this.props.optedIn) {
      let forHref
      const firstSplit = number.split('(')
      const secondSplit = firstSplit[1].split(')')
      const thirdSplit = secondSplit[1].split('-')
      const final = [secondSplit[0], thirdSplit[0], thirdSplit[1]]
      const noFormat = final.join('')
      const body = `Hi ${this.props.portalData.name.first}, please contact me with more information about ${this.props.listingData.StreetNumber} ${this.props.listingData.StreetName} ${this.props.listingData.StreetSuffix} ${this.props.listingData.UnitNumber ? this.props.listingData.UnitNumber : ''}. Thank You  -${this.props.leadFirstName} ${this.props.leadLastName}`
      if (/Android/i.test(navigator.userAgent)) {
        forHref = 'sms://+1' + noFormat + '?body=' + body
      } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        forHref = 'sms:+1' + noFormat + '&body=' + body
      }
      // console.log(forHref)
      window.open(`${forHref}`, '_self')
    } else {
      this.props.optInModalOpen('textRealData')
    }
  }

  emailRealData = (email) => {
    if (this.props.optedIn) {
      const body = `Hi ${this.props.portalData.name.first}, please contact me with more information about ${this.props.listingData.StreetNumber} ${this.props.listingData.StreetName} ${this.props.listingData.StreetSuffix} ${this.props.listingData.UnitNumber ? this.props.listingData.UnitNumber : ''}. Thank You  -${this.props.leadFirstName} ${this.props.leadLastName}`
      const forHref = 'mailto:' + email + '?subject=Openhouse Inquiry - OHG&body=' + body
      // console.log(forHref)
      window.open(`${forHref}`, '_self')
    } else {
      this.props.optInModalOpen('emailRealData')
    }
  }

  emailDefaultData = (email) => {
    if (this.props.optedIn) {
      const body = `Hi ${this.props.portalData.name.first}, please contact me with more information about ${this.props.defaultListingData.StreetNumber} ${this.props.defaultListingData.StreetName} ${this.props.defaultListingData.StreetSuffix} ${this.props.defaultListingData.UnitNumber ? this.props.defaultListingData.UnitNumber : ''}. Thank You  -${this.props.leadFirstName} ${this.props.leadLastName}`
      const forHref = 'mailto:' + email + '?subject=Openhouse Inquiry - OHG&body=' + body
      // console.log(forHref)
      window.open(`${forHref}`, '_self')
    } else {
      this.props.optInModalOpen('emailDefaultData')
    }
  }

  openLink = () => {
    window.open('https://drive.google.com/file/d/1SZdNETfAcPd3wOwxId2RXx_fjs7irZeM/view', '_blank')
  }

  render () {
    return (
      <React.Fragment>
        {this.props.loaded &&
        <Segment className='profile-agent-sect-color no-border' size='large' style={{ borderTopRightRadius: '60px', borderTopLeftRadius: '60px' }}>
          {/* <a href="sms:+919876543210?&amp;body= I%27d%20like%20to%20set%20up%20an%20appointment%20for...">click to send</a>
          <a href='sms:+18664504185&body=Hi%2520there%252C%2520I%2527d%2520like%2520to%2520place%2520an%2520order%2520for...'>Click</a> */}
          <Segment.Group>
            <Grid columns={3}>
              <Grid.Column className="d-flex justify-content-end" style={{ width: '20%', paddingRight: '0' }}><Image className="avatar-img" style={{ marginRight: '0' }} src={this.props.portalData.profilepicture.url} avatar /></Grid.Column>
              <Grid.Column className='d-flex flex-column justify-content-center'>
                <Grid.Row style={{ color: 'white' }}>{this.props.portalData.name.first} {this.props.portalData.name.last}</Grid.Row>
                <Grid.Row className='fancy-font-style' style={{ color: '#c4c4c4', fontSize: '.8rem' }}>{this.props.portalData.slogan.text}</Grid.Row>
              </Grid.Column>
              <Grid.Column style={{ paddingRight: '0' }}>
                <div className='main-font-style' style={{ color: '#c4c4c4', fontSize: '.6rem', textAlign: 'right' }}>
                  <p style={{ margin: '0', padding: '0' }}>{this.props.portalData.mlsinfo.brokerOfficeName}</p>
                  <p style={{ margin: '0', padding: '0' }}>{this.props.portalData.mlsinfo.brokerid}</p>
                  <p style={{ margin: '0', padding: '0' }}>{this.props.portalData.mlsinfo.brokerOfficePhone}</p>
                </div>
              </Grid.Column>
            </Grid>
          </Segment.Group>
          <Segment.Group>
            <p style={{ color: 'white', textAlign: 'center', cursor: 'pointer' }} onClick={this.openLink} className='main-font-style resp-font-size blink'>Click to download a free copy of my &quot;House Buying Handbook&quot;</p>
          </Segment.Group>
          <Segment.Group>
            <Grid columns={3}>
              <Grid.Column style={{ width: '20%' }}></Grid.Column>
              <Grid.Column style={{ width: '60%' }}>
                <div style={{ width: '100%' }} className='d-flex justify-content-center'>
                  {!this.props.optInModal &&
                  <Popup hoverable inverted position='top center' trigger={<Button className='variable-buttons main-font-style show-button' style={{ color: 'white' }}>Schedule a Private Showing</Button>}>
                    <Grid className={this.props.theme} divided columns='equal'>
                      <Grid.Column>
                        <Icon name='call' onClick={() => this.call(this.props.portalData.mobile.number)} />
                      </Grid.Column>
                      <Grid.Column>
                        {this.props.defaultListingData && !this.props.listingData &&
                        <Icon name='talk' onClick={() => this.textDefaultData(this.props.portalData.mobile.number)} />
                        }
                        {this.props.listingData && !this.props.defaultListingData &&
                        <Icon name='talk' onClick={() => this.textRealData(this.props.portalData.mobile.number)} />
                        }
                      </Grid.Column>
                      <Grid.Column>
                        {this.props.defaultListingData && !this.props.listingData &&
                        <Icon name='mail' onClick={() => this.emailDefaultData(this.props.portalData.name.email)} />
                        }
                        {this.props.listingData && !this.props.defaultListingData &&
                        <Icon name='mail' onClick={() => this.emailRealData(this.props.portalData.name.email)} />
                        }
                      </Grid.Column>
                    </Grid>
                  </Popup>
                  }
                </div>
              </Grid.Column>
              <Grid.Column className="arrow-col-pad" style={{ width: '20%', paddingLeft: '0', marginLeft: '0' }}>
                <Grid.Row style={{ padding: '0', margin: '0' }}>
                  <p style={{ margin: '0', padding: '0', color: 'white', textAlign: 'center' }} className="arrow-font">See more Open Houses Below</p>
                </Grid.Row>
                <Grid.Row>
                  <div style={{ width: '100%' }} className="d-flex flex-column justify-content-center arrow-icon-div">
                    <Icon onClick={this.scrollDown} style={{ color: 'white' }} name="arrow alternate circle down outline" className="arrow-icon" fitted />
                  </div>
                </Grid.Row>
              </Grid.Column>
            </Grid>
          </Segment.Group>
        </Segment>
        }
      </React.Fragment>
    )
  }
}

export default AgentDetails
