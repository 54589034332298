import React from 'react'
import { Modal, Button, Icon } from 'semantic-ui-react'
import Carousel from 'react-bootstrap/Carousel'

class ImageModal extends React.Component {
  state = {

  }

  render () {
    return (
      <Modal
        basic
        onClose={this.props.imageModalClose}
        onOpen={this.props.imageModalOpen}
        open={this.props.imageModal}
        size='small'
      >
        <Modal.Content>
          {!this.props.featuredOpenHouseData &&
          <Carousel variant="dark" indicators={false}>
            {this.props.defaultListingData.Media.map((image) => (
              <Carousel.Item key={image.MediaURL}>
                <img
                  className="d-block w-100"
                  src={image.MediaURL}
                />
              </Carousel.Item>
            ))}
          </Carousel>
          }
          {this.props.featuredOpenHouseData &&
          <Carousel variant="dark" indicators={false}>
            {this.props.listingData.Media.map((image) => (
              <Carousel.Item key={image.MediaURL}>
                <img
                  className="d-block w-100"
                  src={image.MediaURL}
                />
              </Carousel.Item>
            ))}
          </Carousel>
          }
        </Modal.Content>
        <Modal.Actions>
          <Button inverted onClick={this.props.imageModalClose}>
            <Icon name='remove' /> Close
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default ImageModal
