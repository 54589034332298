import React from 'react'
import { Segment, Image, Rail } from 'semantic-ui-react'
import moment from 'moment'
import ImageModal from './ImageModal'

class ImageSection extends React.Component {
  state = {
    defaultOpenHouseTimes: [
      {
        OpenHouseStartTime: '2019-12-30T05:40:46.861Z',
        OpenHouseEndTime: '2019-12-30T11:00:46.861Z'
      },
      {
        OpenHouseStartTime: '2019-07-30T05:40:46.861Z',
        OpenHouseEndTime: '2019-07-30T11:00:46.861Z'
      },
      {
        OpenHouseStartTime: '2019-08-30T05:40:46.861Z',
        OpenHouseEndTime: '2019-08-30T11:00:46.861Z'
      }
    ],
    imageModal: false
  }

  imageModalOpen = () => {
    this.setState({ imageModal: true })
  }

  imageModalClose = (event) => {
    event.stopPropagation()
    // console.log('false')
    this.setState({ imageModal: false })
  }

  convertDate = (input) => {
    // console.log(input, 'date')
    const myDate = new Date(input)
    // console.log(myDate)
    const dateString = myDate.toString()
    // console.log(dateString)
    const dateArr = dateString.split(' ')
    // console.log(dateArr)
    const newArr = [dateArr[0], dateArr[1], dateArr[2]]
    // console.log(newArr)
    return newArr.join(' ')
  }

  convertTime = (input) => {
    // console.log(input, 'time')
    const myDate = new Date(input)
    // console.log(myDate)
    const toLocal = myDate.toString()
    const dateArr = toLocal.split(' ')
    // console.log(dateArr)
    const string = dateArr[4]
    return moment(string, 'HH:mm:ss').format('h:mm a')
  }

  render () {
    return (
      <Segment className={this.props.theme + ' ' + 'ratio ratio-5x3'} style={{ borderRadius: '0', border: 'none', padding: '0' }}>
        {this.props.loaded &&
        <div onClick={this.imageModalOpen}>
          <React.Fragment>
            {this.props.featuredOpenHouseData &&
            <Image src={this.props.listingData.Media[0].MediaURL} fluid />
            }
            {!this.props.featuredOpenHouseData &&
            <Image src={this.props.defaultListingData.Media[5].MediaURL} fluid />
            }
          </React.Fragment>
          <React.Fragment>
            {this.props.featuredOpenHouseData &&
            <Rail internal size='mini' position='right' style={{ marginRight: '0', paddingRight: '.3rem' }}><div style={{ width: '100%' }} className='d-flex justify-content-end'><Segment compact size='mini' style={{ color: 'white', backgroundColor: 'rgba(46,46,61,0.50)', border: 'none', padding: '.2rem .4rem .2rem .3rem' }} className='secondary-font-style arrow-font'><p style={{ margin: '0', padding: '0' }}>Listed By:</p><p style={{ margin: '0', padding: '0' }}>{this.props.listingData.ListOfficeName}</p></Segment></div></Rail>
            }
            {!this.props.featuredOpenHouseData &&
            <Rail internal size='mini' position='right' style={{ marginRight: '0', paddingRight: '.3rem' }}><div style={{ width: '100%' }} className='d-flex justify-content-end'><Segment compact size='tiny' style={{ color: 'white', backgroundColor: 'rgba(46,46,61,0.50)', border: 'none', padding: '.2rem .4rem .2rem .3rem' }} className='secondary-font-style arrow-font'><p style={{ margin: '0', padding: '0' }}>Listed By:</p><p style={{ margin: '0', padding: '0' }}>{this.props.defaultListingData.ListOfficeName}</p></Segment></div></Rail>
            }
          </React.Fragment>
          <Rail internal position='left' style={{ marginTop: '2rem', marginLeft: '0', paddingLeft: '0' }}><Segment compact size='huge'style={{ color: 'white', backgroundColor: 'rgba(46,46,61,0.75)', border: 'none', borderTopLeftRadius: '0', borderBottomLeftRadius: '0', borderTopRightRadius: '20px', borderBottomRightRadius: '20px', padding: '.2rem .4rem .2rem .3rem', letterSpacing: '.2rem' }} className='fancy-font-style'>Featured Open House</Segment></Rail>
          {this.props.featuredOpenHouseData &&
          <React.Fragment>
            <Rail internal position='left' style={{ width: '100%', marginLeft: '0', paddingLeft: '0' }}><Segment size='huge' className='secondary-font-style date-rail' textAlign='center' style={{ padding: '0', color: 'white', backgroundColor: 'rgba(46,46,61,0.75)', letterSpacing: '.2rem' }}>{this.props.featuredOpenHouseData ? this.convertDate(this.props.featuredOpenHouseData.Dates[0].OpenHouseStartTime) : 'N/A'} | {this.props.featuredOpenHouseData ? this.convertTime(this.props.featuredOpenHouseData.Dates[0].OpenHouseStartTime) : 'N/A' } - {this.props.featuredOpenHouseData ? this.convertTime(this.props.featuredOpenHouseData.Dates[0].OpenHouseEndTime) : 'N/A'}</Segment></Rail>
            <ImageModal imageModalClose={this.imageModalClose} imageModalOpen={this.imageModalOpen} {...this.state} {...this.props}></ImageModal>
          </React.Fragment>
          }
          {!this.props.featuredOpenHouseData &&
          <React.Fragment>
            <Rail internal position='left' style={{ width: '100%', marginLeft: '0', paddingLeft: '0' }}><Segment size='huge' className='secondary-font-style date-rail' textAlign='center' style={{ padding: '0', color: 'white', backgroundColor: 'rgba(46,46,61,0.75)', letterSpacing: '.2rem' }}>{this.convertDate(this.state.defaultOpenHouseTimes[0].OpenHouseStartTime)} {this.convertTime(this.state.defaultOpenHouseTimes[0].OpenHouseStartTime)} - {this.convertTime(this.state.defaultOpenHouseTimes[0].OpenHouseEndTime)}</Segment></Rail>
            <ImageModal imageModalClose={this.imageModalClose} imageModalOpen={this.imageModalOpen} {...this.state} {...this.props}></ImageModal>
          </React.Fragment>
          }
        </div>
        }
      </Segment>
    )
  }
}

export default ImageSection
