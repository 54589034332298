import React from 'react'
import { Segment, Grid, Icon } from 'semantic-ui-react'

class PropertyDetails extends React.Component {
  state = {
  }

  calcPricePerSqft = () => {
    let price
    if (!this.props.featuredOpenHouseData) {
      price = parseFloat(this.props.defaultListingData.ListPrice) / parseFloat(this.props.defaultListingData.LivingArea)
    } else {
      price = parseFloat(this.props.listingData.ListPrice) / parseFloat(this.props.listingData.LivingArea)
    }
    if (price.toString().length < 6) {
      return price
    } else {
      return price.toString().substring(0, 6)
    }
  }

  // mapSelector = (lat, long) => {
  //   console.log('hi')
  //   if ((navigator.platform.indexOf('iPhone') !== -1) || (navigator.platform.indexOf('iPad') !== -1) || (navigator.platform.indexOf('iPod') !== -1)) {
  //     window.open(`maps://maps.google.com/maps?daddr=${lat},${long}&amp;ll=`)
  //   } else {
  //     window.open(`https://maps.google.com/maps?daddr=${lat},${long}&amp;ll=`)
  //   }
  // }

  mapSelector = (number, name, suffix, city, state, zip) => {
    window.open(`http://maps.apple.com/?t=h&daddr=${number} ${name} ${suffix}, ${city}, ${state}, ${zip}`)
  }

  render () {
    const defaultData = this.props.loaded && !this.props.featuredOpenHouseData && this.props.defaultListingData
    const realData = this.props.loaded && this.props.featuredOpenHouseData
    return (
      <React.Fragment>
        {defaultData &&
        <Segment className={this.props.theme} style={{ borderTopRightRadius: '60px', borderTopLeftRadius: '60px', padding: '0', borderTopColor: 'white', borderTopWidth: 'thin', opacity: '.985', borderBottom: 'none' }}>
          <Segment.Group style={{ padding: '3.5rem 1.5rem 0 1.5rem', height: '100%' }} className='d-flex justify-content-around'>
            <Segment size='large' className={this.props.theme + ' ' + 'no-border'}>
              <Grid columns={2}>
                <Grid.Column style={{ width: '65%' }} className={this.props.theme + ' ' + 'main-font-style resp-font-size'}>
                  <div style={{ paddingLeft: '1rem' }} className='variable-main-font-color'>{this.props.defaultListingData.StreetNumber} {this.props.defaultListingData.StreetName} {this.props.defaultListingData.StreetSuffix} {this.props.defaultListingData.UnitNumber ? this.props.defaultListingData.UnitNumber : ''}</div>
                  <div style={{ color: '#c4c4c4', paddingLeft: '1rem' }}>{this.props.defaultListingData.City}, {this.props.defaultListingData.StateOrProvince} {this.props.defaultListingData.PostalCode}</div>
                </Grid.Column>
                <Grid.Column style={{ width: '35%' }} className={'d-flex align-items-center justify-content-center' + ' ' + this.props.theme}>
                  <Icon onClick={() => this.mapSelector(this.props.defaultListingData.StreetNumber, this.props.defaultListingData.StreetName, this.props.defaultListingData.StreetSuffix, this.props.defaultListingData.City, this.props.defaultListingData.StateOrProvince, this.props.defaultListingData.PostalCode)} name='location arrow' size='big' className={this.props.theme + ' ' + 'variable-checks'} />
                </Grid.Column>
              </Grid>
            </Segment>
            <Segment className={this.props.theme + ' ' + 'no-border'}>
              <Grid style={{ color: '#c4c4c4' }} className="main-font-style variable-main-font-color d-flex justify-content-evenly" columns={3}>
                <Grid.Column className='desc-card-bg-color' style={{ borderRadius: '15px', width: '31%' }}>
                  <Grid.Row className='d-flex justify-content-center mb-2'><Icon fitted size='big' name='bed' className='variable-checks' style={{ backgroundColor: 'transparent' }} /></Grid.Row>
                  <Grid.Row className='d-flex justify-content-center'>{this.props.defaultListingData.BedroomsTotal} Beds</Grid.Row>
                </Grid.Column>
                <Grid.Column className='desc-card-bg-color' style={{ borderRadius: '15px', width: '31%' }}>
                  <Grid.Row className='d-flex justify-content-center mb-2'><Icon fitted size='big' name='bath' className='variable-checks' style={{ backgroundColor: 'transparent' }} /></Grid.Row>
                  <Grid.Row className='d-flex justify-content-center'>{this.props.defaultListingData.BathroomsFull} Full Baths</Grid.Row>
                </Grid.Column>
                <Grid.Column className='desc-card-bg-color' style={{ borderRadius: '15px', width: '31%' }}>
                  <Grid.Row className='d-flex justify-content-center mb-2'><Icon fitted size='big' name='arrows alternate' className='variable-checks' style={{ backgroundColor: 'transparent' }} /></Grid.Row>
                  <Grid.Row className='d-flex justify-content-center'>{this.props.defaultListingData.LivingArea.toLocaleString()} SQFT</Grid.Row>
                </Grid.Column>
              </Grid>
            </Segment>
            <Segment className={this.props.theme + ' ' + 'no-border'}>
              <Grid className="main-font-style variable-main-font-color" columns={3}>
                <Grid.Column>
                  <Grid.Row className='d-flex justify-content-center' style={{ color: '#c4c4c4' }}>Listing Price:</Grid.Row>
                  <Grid.Row className='d-flex justify-content-center' style={{ fontSize: '1rem' }}>${this.props.defaultListingData.ListPrice.toLocaleString()}</Grid.Row>
                </Grid.Column>
                <Grid.Column>
                  <Grid.Row className='d-flex justify-content-center' style={{ color: '#c4c4c4' }}>DOM:</Grid.Row>
                  <Grid.Row className='d-flex justify-content-center' style={{ fontSize: '1rem' }}>12 Days</Grid.Row>
                </Grid.Column>
                <Grid.Column>
                  <Grid.Row className='d-flex justify-content-center' style={{ color: '#c4c4c4' }}>Price Per Sqft:</Grid.Row>
                  <Grid.Row className='d-flex justify-content-center' style={{ fontSize: '1rem' }}>${this.calcPricePerSqft()}</Grid.Row>
                </Grid.Column>
              </Grid>
            </Segment>
          </Segment.Group>
        </Segment>
        }
        {realData &&
        <Segment className={this.props.theme} style={{ borderTopRightRadius: '60px', borderTopLeftRadius: '60px', padding: '0', borderTopColor: 'white', borderTopWidth: 'thin', opacity: '.985', borderBottom: 'none' }}>
          <Segment.Group style={{ padding: '3.5rem 1.5rem 0 1.5rem', height: '100%' }} className='d-flex justify-content-around'>
            <Segment size='large' className={this.props.theme}>
              <Grid columns={2}>
                <Grid.Column style={{ width: '65%' }} className={this.props.theme + ' ' + 'main-font-style resp-font-size'}>
                  <div style={{ paddingLeft: '1rem' }} className='variable-main-font-color'>{this.props.listingData.StreetNumber} {this.props.listingData.StreetName} {this.props.listingData.StreetSuffix} {this.props.listingData.UnitNumber ? this.props.listingData.UnitNumber : ''}</div>
                  <div style={{ color: '#c4c4c4', paddingLeft: '1rem' }}>{this.props.listingData.City}, {this.props.listingData.StateOrProvince} {this.props.listingData.PostalCode}</div>
                </Grid.Column>
                <Grid.Column style={{ width: '35%' }} className={'d-flex align-items-center justify-content-center' + ' ' + this.props.theme}>
                  <Icon onClick={() => this.mapSelector(this.props.listingData.StreetNumber, this.props.listingData.StreetName, this.props.listingData.StreetSuffix, this.props.listingData.City, this.props.listingData.StateOrProvince, this.props.listingData.PostalCode)} name='location arrow' size='big' className={this.props.theme + ' ' + 'variable-checks'} />
                </Grid.Column>
              </Grid>
            </Segment>
            <Segment className={this.props.theme + ' ' + 'no-border'}>
              <Grid style={{ color: '#c4c4c4' }} className="main-font-style variable-main-font-color d-flex justify-content-evenly" columns={3}>
                <Grid.Column className='desc-card-bg-color' style={{ borderRadius: '15px', width: '31%' }}>
                  <Grid.Row className='d-flex justify-content-center mb-2'><Icon fitted size='big' name='bed' className='variable-checks' style={{ backgroundColor: 'transparent' }} /></Grid.Row>
                  <Grid.Row className='d-flex justify-content-center'>{this.props.listingData.BedroomsTotal} Beds</Grid.Row>
                </Grid.Column>
                <Grid.Column className='desc-card-bg-color' style={{ borderRadius: '15px', width: '31%' }}>
                  <Grid.Row className='d-flex justify-content-center mb-2'><Icon fitted size='big' name='bath' className='variable-checks' style={{ backgroundColor: 'transparent' }} /></Grid.Row>
                  <Grid.Row className='d-flex justify-content-center'>{this.props.listingData.BathroomsFull} Full Baths</Grid.Row>
                </Grid.Column>
                <Grid.Column className='desc-card-bg-color' style={{ borderRadius: '15px', width: '31%' }}>
                  <Grid.Row className='d-flex justify-content-center mb-2'><Icon fitted size='big' name='arrows alternate' className='variable-checks' style={{ backgroundColor: 'transparent' }} /></Grid.Row>
                  <Grid.Row className='d-flex justify-content-center'>{this.props.listingData.LivingArea.toLocaleString()} SQFT</Grid.Row>
                </Grid.Column>
              </Grid>
            </Segment>
            <Segment className={this.props.theme + ' ' + 'no-border'}>
              <Grid className="main-font-style variable-main-font-color" columns={3}>
                <Grid.Column>
                  <Grid.Row className='d-flex justify-content-center' style={{ color: '#c4c4c4' }}>Listing Price:</Grid.Row>
                  <Grid.Row className='d-flex justify-content-center' style={{ fontSize: '1rem' }}>${this.props.listingData.ListPrice.toLocaleString()}</Grid.Row>
                </Grid.Column>
                <Grid.Column>
                  <Grid.Row className='d-flex justify-content-center' style={{ color: '#c4c4c4' }}>DOM:</Grid.Row>
                  <Grid.Row className='d-flex justify-content-center' style={{ fontSize: '1rem' }}>{this.props.listingData.DaysOnMarket} Days</Grid.Row>
                </Grid.Column>
                <Grid.Column>
                  <Grid.Row className='d-flex justify-content-center' style={{ color: '#c4c4c4' }}>Price Per Sqft:</Grid.Row>
                  <Grid.Row className='d-flex justify-content-center' style={{ fontSize: '1rem' }}>${this.calcPricePerSqft()}</Grid.Row>
                </Grid.Column>
              </Grid>
            </Segment>
          </Segment.Group>
        </Segment>
        }
      </React.Fragment>
    )
  }
}

export default PropertyDetails
