import React from 'react'
import DefaultListings from './DefaultListings'
import RealListings from './RealListings'

class Listings extends React.Component {
  state = {

  }

  render () {
    return (
      <React.Fragment>
        {this.props.featuredOpenHouseData &&
        <RealListings optInModalOpen={this.props.optInModalOpen} {...this.props}></RealListings>
        }
        {!this.props.featuredOpenHouseData &&
        <DefaultListings optInModalOpen={this.props.optInModalOpen} {...this.props}></DefaultListings>
        }
      </React.Fragment>
    )
  }
}

export default Listings
