import React from 'react'
import ImageSection from './ImageSection'
import PropertyDetails from './PropertyDetails'
import AgentDetails from './AgentDetails'

class Profile extends React.Component {
  state = {
  }

  render () {
    return (
      <div className="profile-wrapper">
        <div style={{ height: '100%', backgroundColor: 'white' }} className="d-flex flex-column justify-content-center">
          <div className={'profile-view' + ' ' + this.props.theme }>
            <ImageSection {...this.props}></ImageSection>
            <PropertyDetails {...this.props}></PropertyDetails>
            <AgentDetails {...this.props}></AgentDetails>
          </div>
        </div>
      </div>
    )
  }
}

export default Profile
