/* eslint-disable no-tabs */
import React, { Component, Fragment } from 'react'
import MainView from './components/MainView/MainView'
import Error404View from './components/MainView/Error404View'
import LandingView from './components/MainView/LandingView'
import { Route } from 'react-router-dom'
// import { v4 as uuid } from 'uuid'

// import AuthenticatedRoute from './components/AuthenticatedRoute/AuthenticatedRoute'
// import AutoDismissAlert from './components/AutoDismissAlert/AutoDismissAlert'
// import Header from './components/Header/Header'
// import SignUp from './components/auth/SignUp'
// import SignIn from './components/auth/SignIn'
// import SignOut from './components/auth/SignOut'
// import ChangePassword from './components/auth/ChangePassword'

class App extends Component {
  constructor (props) {
    super(props)
    this.state = {
      user: null,
      msgAlerts: []
    }
  }

  // setUser = (user) => this.setState({ user })

  // clearUser = () => this.setState({ user: null })

  // deleteAlert = (id) => {
  //   this.setState((state) => {
  //     return { msgAlerts: state.msgAlerts.filter((msg) => msg.id !== id) }
  //   })
  // }

  // msgAlert = ({ heading, message, variant }) => {
  //   const id = uuid()
  //   this.setState((state) => {
  //     return {
  //       msgAlerts: [...state.msgAlerts, { heading, message, variant, id }]
  //     }
  //   })
  // }

  render () {
    // const { msgAlerts, user } = this.state

    return (
      <Fragment>
	      <main className=''>
          <Route
            exact path='/'
            render={() => (
              <LandingView></LandingView>
            )}
          />
          <Route
            exact path='/notfound'
            render={() => (
              <Error404View></Error404View>
            )}
          />
          <Route
            exact path='/:id'
            render={() => (
              <MainView></MainView>
            )}
          />
        </main>
      </Fragment>
    )
  }
}

export default App
